.brdr_b1{ border-width:0 0 1px !important;}
.top-10px{ top:-10px  !important;}
.width92_sup{ width:92%;}
.cross_fill{    border: 1px solid #ddd;
    width: 22% !important;
    min-height: 130px;
    margin: 10px 0 0;}
	.customfom { 
    color: #ee1c25;}
	.self_attach{ text-align:center; padding:40px 0 0; color:#81898b;}
.ship_check{    margin: 0 2px;
    position: relative;
    top: 2px;}	
	.width57px {
    width: 57px !important;
}
.pad_r3{ padding-right:3px;}
.fnt7{ font-size:7px !important}
.fnt8{ font-size:8px !important}
.wid50per{ width:50% !important;}
.ssidname.check input{ top:0;}
.wifitable{ padding:5px 0;}
.wifitable table {
    text-align: left;
    border: none;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
}
 
.wifitable table td {
    border: 1px solid #ccc; text-align:center;
    border-collapse: collapse;
    padding: 1px 5px;
    border-width: 0 1px 1px 0;
}
.width78{ width:78%;}
.list li { 
    list-style-type: none;
}
.list {
    padding: 20px 0 0 0px;}
 .width33{ width:33%;}
.fon8_5{ font-size:8.5px;}
.othercheck{top: 3px !important;
    margin: 0;
    position: relative;}
.posinput{position: relative;
    top: -13px !important;}
.font7{ font-size:7px;}	
	.font_8{ font-size:8px;}
.pad_l_2{ padding-left:10px;}	
.in_subput input{    border: 1px solid #ccc;
    padding: 2px 5px;
    font-size: 10px;
    position: relative;
    border-width: 0 0 1px 0;
    top: -2px; width:50px;}	
.wid83p{ width:83% !important}	
	.wid80p{ width:78% !important}
.pad_l_5{ padding-left:5px;}
.signsealwith{ padding: 70px 0 0;
    font-size: 8px;}	
.width34{ width:34%;}	
.activatesim{ border:1px solid #ddd;}
.width40px{ width:40px !important;}
.width100per{ width:85% !important;}
.vilemp{ text-align:center; padding:50px 0 0;}
.terms{ color:#ca685f; text-transform:uppercase;}
.width150px{ width:150px !important}

.tariff{ text-align:center; font-size:18px; margin:10px 0 5px;}
.e1{ text-align:center; font-size:16px; margin:0;}
.width5{ width:5%;}
.tariff_hed{ font-size:18px; padding:20px 0 0 ;}
.tariff_table table{ border:1px solid #ddd;}
.tariff_table th{ padding:5px 10px; border:1px solid #ccc; background:#ddd;}
.tariff_table td{ padding:5px 10px; border:1px solid #ddd; background:#fff;}
.tariff_table{ padding:0 0 15px;}
.pad_l20{ padding-left:20px;}
.width33{ width:33%;}
.sealright{ float:right; padding:10px; width:300px; border:1px solid #ccc;}
.termleft{ float:left; width:49%;}
.termright{ float:right; width:49%;}
.generalterms{ padding:30px 0;}
.tarifflist{    list-style-type: decimal;padding: 0 0 0 15px; }
.tarifflist2{    list-style-type: decimal; margin:0; padding:0 0 0 10px;}
.tarifflist li{ padding-bottom:5px;}
.tarifflist3{    list-style-type: none; margin:0 0 0 -10px; padding:0 0 0 0px;}
.tarifflist4{    list-style-type: none; margin:0 0 0 -10px; padding:0 0 0 0px;}
.tarifflist4 li{ padding:0 0 5px;}
.sealleft{ float:left; padding:10px; width:300px; border:1px solid #ccc;}
.pad_r15_pri {
    padding-right: 15px;
    text-align: justify;
}
.in_subput_date input{    border: 1px solid #ccc;
    padding: 2px 5px;
    font-size: 10px;
    position: relative;
    border-width: 0 0 1px 0;
    top: -2px; width:65px;} 













	
	
	
	
	