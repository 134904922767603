@font-face {
  font-family: 'DS-Digital';
  src: local('DS-Digital'), url(./assets/fonts/ds-digital/DS-DIGIT.TTF) format('TTF');
}


body {
  margin: 0;
  padding: 0;
  font-family: "DS-Digital", 'Open Sans', sans-serif;
}