 .cross_fill {
    border: 1px solid #ddd;
    width: 22% !important;
    min-height: 130px;
    margin: 10px 0 0;
}.self_attach {
    text-align: center;
    padding: 20px 0 0;
    color: #ccc;
}
.width48{ width:48%;}
.width35{ width:35%;}	
.font7{ font-size:8.3px;}	
.wid12per{ width:11%;} 
.sealone{ position:relative; border:1px solid #ccc; min-height:100px; margin:5px 0 0 10px;}	
.sealtext1{ position:absolute; left: 10px;
    right: 10px;
    bottom: 3px;}	
	.sealtext2{     position: absolute;
    top: 30px;
    left: 10px;
    right: 10px;
    text-align: center;
    font-size: 17px;
    color: #ccc;}.sealtext3{      position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    font-size: 13px;
    color: #ccc;
    line-height: 13px;}.sealtext4{position: absolute;
    left: 5px;
    right: 5px;
    bottom: 0;}
	.sealtwo{ position:relative; border:1px solid #ccc; min-height:80px; margin:5px 0 0 10px;}	
.sealfour	{ position:relative; border:1px solid #ccc; min-height:60px; margin:5px 0 0 10px;}	
.vodahedcol{ position:absolute; top:3px; right:5px; width:55%;}	
.vodahedcol p{ margin:0;}	
.vodahedcol p input	{ height:8px;}
.vodatex{position: absolute;
    left: 10px;
    top: 15px;
    font-size: 14px;
    color: #ccc;}	
	.vodasign{ position:absolute; bottom:5px; left:5px;}
	.pad_t40{ padding-top:40px;}
.asent{    position: absolute;
    left: 0;
    font-size: 7px;
    top: 13px;
    line-height: 8px;
    width: 66px;}	
.relati{ position:relative;}	
.sealone_2 {
    position: relative;
    border: 1px solid #ccc;
    min-height: 80px;
    margin: 5px 0 0 10px;
}
.sealtext21 {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    font-size: 14px;
    color: #ccc;
    line-height: 13px;
}
.width33{ width:33%;}
.width47{ width:47%;}
.cuttingcard{ height:2px; border-bottom:1px dashed #FF0000; margin:30px -40px;}
.custext{ margin:20px 0 0}
.signature_box {
    border: 1px solid #ccc;
    margin: 0px 0 0;
    padding: 5px 6px 20px;
    text-align: center;
    font-size: 9.5px;
}




	