.grene-loader-modal {
    z-index: 100000;
    max-width: 250px;
    min-width: 250px;
    padding: 0;

    .err-title {
        padding: 5px 10px;
        text-align: center;
        font-weight: 500;
    }

    .loader-container {
        background: #e4e4e5;
        min-height: 250px;
        max-height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 4px;
    }
}