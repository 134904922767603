.cover-page,
.cover-page-last {
    background: #60194B;
    padding: 20px 50px;
    color: #ffffff;
}
@media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
      margin-bottom: 1rem;
    }
  }
@media print {
    .cover-page, .cover-page-last {  
        background: #60194B !important;
        color: #ffffff !important;
        -webkit-print-color-adjust: exact;
        height: 90% !important;
        overflow: initial !important;
    }
    .page-container{
        height: 90% !important;
        overflow: initial !important;
    }

}
.cover-page-last {
    padding: 0px;
}

.product-container {
    padding: 10px 0px;
    margin: 5px 0px;
    display: flex;
    width: 100%;
    background: #EE2737;
    justify-content: space-around;
    align-items: center;
}

.product-container div p {
    margin: 0
}

.product-image {
    width: 100%;
}

.product-container div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5px 0px;
}

.page-footer {
    width: 100%;
}

.footer-title {
    padding-left: 50px;
}

.page-container {
    min-height: inherit;
    height: 100%;
}

.last-page-container {
    min-height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    flex-grow: 1;
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
}

.vil {
    width: 200px;
    height: 200px;
    margin-bottom: 150px;
}
.view-type-company,.view-type-name,.product-name{
    color: #fff;
}

.view-type-name {
    border-bottom: 2px solid #ee2737;
    width: 44%;
    font-weight: 300;
    padding-bottom: 10px;
}

.section-header {
    display: flex;
}

.section-note {
    margin-left: 5px;
    border-left: 1px solid;
}

.vil-logo {
    margin-right: 10px;
}