.no-header {
    .action_area {
        display: none;
    }
}
.cafcrf-view-container {
    background-color: transparent;

    .cafcrf-title-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 40px;
        background: #131d0b;
        color: #ffffff;
        padding: 0 5px;
        border-radius: 7px 7px 0 0;

        .cafcrf-title {
            font-size: 16px;
            font-weight: 400;
            padding-left: 10px;
        }
    }

    .cafcrf-overview-container {
        width: 100%;
        overflow-y: scroll;
        height: 91vh;
        justify-content: space-between;
        align-items: center;
        background: #e4e4e4;
        padding: 0 5px 30px 5px;
    }
    @media print {
        
            // background-color: #1a4567 !important;
            -webkit-print-color-adjust: exact; 
        // }
    }
}