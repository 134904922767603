.wid40px {
    width: 40px !important;
}

.wid35px {
    width: 30px !important;
}

.width200px {
    width: 200px !important;
}

.width150px {
    width: 150px !important;
}

.width30px {
    width: 30px !important;
}

.width50px {
    width: 50px !important;
}

.width20px {
    width: 20px !important;
}

.width10px {
    width: 10px !important;
}

.width72px {
    width: 72px;
}