.servaction{ margin:8px 0 0;} 
.serveval{ margin:-1px 0 0;}
.tilltext{    display: inline-block;
    width: 75px;
    text-align: right; 
    position: relative;
    top: 3px;}
.width27{ width:27%;}
.iftext{    margin: -6px 0 5px 11px;
    padding: 0;}
.useaddi{ margin:-5px 0 0;}
.width67{ width:67%;}
.height100{ height:60px;}
.pad_30t{ padding-top:30px;}
.width48{ width:48%;}
.domestictable table td{ text-align:left;}
.design{ float:none !important;}
.pad10{ padding:10px;}
.nolist{    padding: 0px 0 0 0px;
    list-style-type: none;}

.nolist li {
    list-style-type: none;
    display: flex;
}
.nolist li {
    margin: 0 0 10px;
}
.nolist li span {
    display: flex;
}

.in_subput_date input{    border: 1px solid #ccc;
    padding: 2px 5px;
    font-size: 10px;
    position: relative;
    border-width: 0 0 1px 0;
    top: -2px; width:63px;} 

.in_subput input{    border: 1px solid #ccc;
    padding: 2px 5px;
    font-size: 10px;
    position: relative;
    border-width: 0 0 1px 0;
    top: -2px; width:50px;}	
	
	
	