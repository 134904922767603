@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

page {
	background: white;
	display: block;
	margin: 0 auto;
    margin-bottom: 0.5cm;/*box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);*/
    width: 21cm;
    min-height: 29.7cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

page[size="A4"] {
    width: 21cm;
    min-height: 1000px;
}

page[size="A4"][layout="landscape"] {
    width: 29.7cm;
}

page[size="A3"] {
    width: 29.7cm;
    height: 42cm;
}

page[size="A3"][layout="landscape"] {
    width: 42cm;
    height: 29.7cm;
}

page[size="A5"] {
    width: 14.8cm;
    height: 21cm;
}

page[size="A5"][layout="landscape"] {
    width: 21cm;
    height: 14.8cm;
}

.section1 {
    padding: 80px;
}

.section1_top {
    text-align: right;
}

.section1_top_col {
    width: auto;
    display: block;
    text-align: left;
}

.pad_l_r10 {
    padding: 0 10px;
}

.over_hid {
    overflow: hidden;
    clear: both;
    padding: 0 0 5px;
    line-height: unset;
    margin: -5px 0 0;
    clear: both;
}

.pullr {
    float: right;
}

.customhed {
    padding: 0 0 0 30px;
    color: #ed1d24;
    font-weight: bold;
    font-size: 20px;
}

.empty1 {
    height: 160px;
}

.vodabar {
    padding: 30px 60px;
    color: #fff;
    background-color: #ee1d23;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    display: block;
    -webkit-print-color-adjust: exact;
    overflow: visible;
}

.connectcol {
    padding: 350px 0 0;
    text-align: center;
}

.ready {
    font-size: 27px;
    color: #ec1d25;
}

.connecticons img {
    margin-right: 55px;
}

.url {
    width: auto;
    text-align: center;
    font-size: 22px;
    color: #838383;
    font-weight: bold;
    margin: 20px 0 0;
}

.section2 {
    padding: 20px 40px;
}

.wid50 {
    width: 50%;
}

.in_block {
    display: inline-block;
}

.pad0 {
    padding: 0 !important;
}

.customhed {
    padding: 0 0 0 0px;
    color: #ec1d23;
    font-weight: normal;
    font-size: 30px;
    margin: 90px 0 8px 0px;
    font-weight: 600;
    text-align: center;
}

@media print {
    body, page {
        margin: 0;
        box-shadow: 0;
    }
    body {
        -webkit-print-color-adjust: exact;
    }
}

.w_85 {
    width: 90%;
}

.font8 {
    font-size: 10px;
}

.caf {
    float: right;
    width: 200px;
    text-align: right;
}

.caf input {
    float: right;
    width: 110px;
    border: 1px solid #ccc;
    padding: 4px 5px;
    font-size: 10px;
    position: relative;
    top: -5px;
    margin: 0 0 0 15px;
}

.pd_t5 {
    padding-top: 5px;
}

.pad_l10 {
    padding-left: 10px;
}

.mar_t-5 {
    margin-top: -5px;
}

.form_col_input {
    border: 1px solid #ccc;
    padding: 2px 0px;
    font-size: 10px;
    border-width: 0 0 1px 0;
    width: 45%;
    position: relative;
    top: -2px;
}

.caf_inner {
    float: right;
    width: 200px;
    line-height: 16px;
}

.caf_inner input {
    float: right;
    width: 110px;
    border: 1px solid #ccc;
    padding: 4px 5px;
    font-size: 10px;
    position: relative;
}

.width45 {
    width: 45%;
}

.fleft {
    float: left;
}

.auth input {
    border: 1px solid #ccc;
    padding: 2px 5px;
    font-size: 10px;
    position: relative;
    border-width: 0 0 1px 0;
    top: -2px;
}

.width25 {
    width: 25%;
}

.width30 {
    width: 30%;
}

.width40 {
    width: 40%;
}

.width45 {
    width: 45%;
}

.width72 {
    width: 72%;
}

.width74 {
    width: 74%;
}

.width60 {
    width: 60%;
}

.width87 {
    width: 87%;
}

.width54 {
    width: 54%;
}

.width58 {
    width: 58%;
}

.width42 {
    width: 42%;
}

.font5 {
    font-size: 8px;
}

.ligthbg {
    background: #d1d3d4;
    padding: 2px 10px;
    font-weight: bold;
    margin: 5px 0px 8px;
}

.width50 {
    width: 50%;
}

.width37px {
    width: 37px;
    display: inline-block;
}

.width70 {
    width: 70%;
}

.check input {
    border: 1px solid #ccc;
    padding: 2px 5px;
    font-size: 10px;
    position: relative;
    top: 3px;
    margin: 0;
    width: 11px;
}

.width15 {
    width: 15%;
}

.width12 {
    width: 11%;
}

.typecheck {
    font-size: 10px;
}

.typecheck .width12 {
    padding-right: 0;
    width: auto;
    padding-right: 13px;
}

.pad_t7 {
    padding-top: 4px;
}

.width110px {
    width: 137px;
}

.width83 {
    width: 83%;
}

.width65px {
    width: 65px;
}

.mplstech {
    width: 20%;
}

.width16 {
    width: 16%;
}

.mplstextin input {
    border: 1px solid #ccc;
    padding: 2px 5px;
    font-size: 10px;
    position: relative;
    top: 3px;
}

.wid12 {
    width: 12%;
}

.custype {
    font-size: 10px;
}

.fnt3_3 {
    font-size: 10px;
}

.fnt4 {
    font-size: 10px;
}

.mpls {
    font-size: 10px;
}

.pad_7t {
    padding-top: 2px;
}

.width70px {
    width: 100px;
}

.width80px {
    width: 80px;
}

.width78 {
    width: 82%;
}

.gstcheck {
    font-size: 10px;
}

.check.gstcheck input.oth_gst {
    top: 2px;
    border-width: 0 0 1px;
    padding: 0;
    width: 80px;
}

.width14 {
    width: 14%
}

.width15_2 {
    width: 16%;
}

.customfom {
    padding: 0 0 0 0px;
    color: #797979;
    font-weight: normal;
    font-size: 20px;
    margin: 0 0 10px 0px;
    font-weight: 400;
}

.width85 {
    width: 85%;
}

.width59 {
    width: 59%;
}

.width80 {
    width: 80%;
}

.width90 {
    width: 90%;
}

.auth {
    clear: both;
    margin: 3px 0 5px;
    padding: 0;
}

.pad_b5 {
    padding-bottom: 5px;
}

.width54px {
    width: 54px;
    display: inline-block;
}

.width65 {
    width: 65%;
}

.width77 {
    width: 77%;
}

.width66 {
    width: 66%;
}

.faxn {
    display: inline-block;
    width: 86px;
    text-align: right;
}

.fnt8 {
    font-size: 8px;
}

.fnt7 {
    font-size: 7px;
}

.fnt6 {
    font-size: 6px;
}

.sign {
    margin: 0;
}

.gsttext {
    margin: 0 0 5px;
}

.auth.remarks {
    margin: -5px 0 5px;
    padding-left: 100px;
}

.auth.namsupp {
    margin: 0 0 5px;
}

.margin0 {
    margin: 0 !important;
}

.mplstextin.padb10 {
    padding-bottom: 10px;
}

.padt5 {
    padding-top: 5px;
}

.signate {
    border: 1px solid #ccc;
    margin: 20px 0 0;
    padding: 70px 6px 20px;
    text-align: center;
    font-size: 9.5px;
}

.width30 {
    width: 30%;
}

.pad_t20 {
    padding-top: 20px;
}

.pad_t30 {
    padding-top: 30px;
}

.width65 {
    width: 65%;
}

.marb5 {
    margin-bottom: 5px;
}

.flright {
    float: right;
}

.char .over_hid.pad_t5 {
    padding-top: 3px;
}

.textarea {
    border: 1px solid #ccc;
    padding: 2px 2%;
    font-size: 10px;
    min-height: 30px;
    width: 95.8%;
}

.mar_l7 {
    margin-left: 7px;
}

.width_12 {
    width: 12%;
}

.pad_t5 {
    padding-top: 5px;
}

.width7 {
    width: 7%;
}

.top-3 {
    top: -3px !important;
}

.pad_t15 {
    padding-top: 15px;
}

.cos .width10 {
    font-size: 7px;
    width: 11%;
}

.font9 {
    font-size: 9px;
}

.fon8 {
    font-size: 8px;
}

.textcenter {
    text-align: center;
}

.lm {
    border-width: 0 0 1px 0 !important;
    width: 77% !important;
    top: -3px !important;
    margin-left: 3px !important;
}

.lm.nill {
    width: 57% !important;
}

.width6 {
    width: 8%;
}

.width52 {
    width: 52%;
}

.width13 {
    width: 13%;
}

.width24 {
    width: 24%;
}

.pd_t3 {
    padding-top: 3px;
}

.font9 {
    font-size: 9px;
}

.width75 {
    width: 75% !important;
}

.width90 {
    width: 90% !important;
}

.width10 {
    width: 10%;
}

.width_6 {
    width: 6%;
}

.width130px {
    width: 130px;
}

.width170px {
    width: 175px;
}

.width110px {
    width: 110px;
}

.padl5 {
    padding-left: 5px;
}

.pad_t3 {
    padding-top: 3px;
}

.pad_t6 {
    padding-top: 6px;
}

.pad_t10 {
    padding-top: 10px;
}

.inputext {
    border: 1px solid #ccc;
    padding: 2px 5px;
    font-size: 10px;
    position: relative;
    border-width: 0 0 1px 0 !important;
    top: -2px;
}

.pad_5t {
    padding-top: 5px;
}

.connecticons span {
    display: inline-block;
    text-align: center;
    width: 20%;
    font-size: 18px;
    font-weight: 600;
    color: #838383;
}

.connecticons span img {
    display: inline-block;
    margin: 0;
}

.domestictable {
    text-align: right;
    padding: 0 0 10px 50px;
    clear: both;
    overflow: hidden;
}

.domestictable table {
    text-align: left;
    border: none;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
}

.domestictable table td {
    border: 1px solid #ccc;
    border-collapse: collapse;
    padding: 1px;
    border-width: 0 1px 1px 0;
}

.pad_b5 {
    padding-bottom: 5px;
}

.regtext {
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px 0 3px;
    border-bottom: 1px solid #ccc;
}

.fright {
    float: right;
}

.width49 {
    width: 49%;
}

.width84 {
    width: 84%;
}

.font6 {
    font-size: 6px;
}

.font10 {
    font-size: 10px;
}

.widthfull {
    width: 100% !important;
}

.inputext_brd {
    border: 1px solid #ccc;
    padding: 1px 5px;
    font-size: 10px;
    position: relative;
    top: 0;
    margin: 0 0 5px;
}

.inputext_brd_list {
    border: 1px solid #ccc;
    padding: 3px 5px;
    font-size: 10px;
    position: relative;
    top: 0;
    margin: 0 0 5px;
}

.design {
    float: right;
    width: 58% !important;
    margin-right: 10px;
}

.list {
    padding: 20px 0 0 15px;
    list-style-type: none;
}

.list li {
    margin: 0 0 10px;
}

.pad_l50 {
    padding-left: 50px;
}

.signate_account {
    border: 1px solid #ccc;
    margin: 20px 0 0;
    padding: 70px 6px 20px;
    text-align: center;
    font-size: 9.5px;
}

.cmt_spl {
    border: 1px solid #ccc;
    margin: 20px 0 5px;
    padding: 4px 6px 50px;
    text-align: left;
    font-size: 9.5px;
}

.pad_50t {
    padding-top: 50px;
}

.width96per {
    width: 96% !important
}

.width92per {
    width: 92% !important
}

.width98per {
    width: 98% !important
}

.mar_l20 {
    margin-left: 20px;
}

.cmnt_input {
    border: 1px solid #ccc;
    padding: 2px 5px;
    font-size: 10px;
    position: relative;
    top: 3px;
    margin: 0;
}

.cpelist {
    width: 9.5%;
}

.cpelist input {
    width: 90% !important;
    border-width: 0 0 1px;
}

.pad_t7 {
    padding-top: 7px;
}

.wid60px {
    width: 60px !important;
}

.pad_r20 {
    padding-right: 20px;
}

.width22 {
    width: 22%;
}

.pad_r25 {
    padding-right: 25px;
}

body .hed5 {
    border-top: 1px solid #aaa;
    padding: 5px 0;
    margin: 10px 0 !important;
    border-bottom: 1px solid #aaa;
}

.domestictable table td {
    text-align: center;
}

.domestictable {
    padding: 0 0 10px 0px;
}

.domestictable table {
    margin: 0 auto;
}

.wid30per {
    width: 30%;
}

.wid20per {
    width: 20%;
}

.pad_r15 {
    padding-right: 15px;
}

.width56im {
    width: 56% !important;
}

.width47im {
    width: 47% !important;
}

.w192px {
    width: 192px;
}

.pad_r15 {
    padding-right: 15px;
}

.pad_r25 {
    padding-right: 25px;
}

.pad_l5per {
    padding-left: 5%;
}

.width81 {
    width: 81%;
}

.width63 {
    width: 63%;
}

.wid25 {
    width: 25%;
}

.signature {
    text-align: right;
    padding-right: 30px;
}

.signcol {
    display: inline-block;
    height: 50px;
    width: 150px;
    margin: 0 0 0 20px;
    border: 1px solid #999;
    text-align: center;
    line-height: 50px;
}

.brd_b {
    border-width: 0 0 1px !important;
}

.width75 {
    width: 75%;
}

.width71 {
    width: 71%;
}

.width20 {
    width: 20%;
}

.pad_t8 {
    padding-top: 8px;
}

.width91 {
    width: 91%;
}

.crossign {
    border: 1px solid #ddd;
    height: 65px;
}

.mand {
    text-align: center;
    padding: 0 0 3px;
    color: #FF0000;
}

.font9 {
    font-size: 9px;
}

.width55 {
    width: 55%;
}

.widthauto {
    width: auto;
}

.isp {
    font-size: 8px;
    padding-top: 6px;
    float: left;
}

.width28 {
    width: 28px !important;
}

.dtcol {
    font-size: 9px;
    padding-top: 6px;
    padding-right: 3px;
    padding-left: 5px;
    float: left;
}

.width50p {
    width: 50px !important;
}

.pad_r5 {
    padding-right: 5px;
}

.band {
    height: 9px;
    width: 70px !important;
}
.band_v1 {
    height: 18px;
    width: 70px !important;
}

.h20 {
    height: 20px;
}

.wid120p {
    width: 120px !important
}

.mnth {
    top: 3px;
    padding-left: 5px;
    position: relative;
}

.sig1 {
    padding-top: 10px;
    padding-right: 0 !important;
}

.text_r {
    text-align: right;
}

.iptable td {
    border: 1px solid #ccc;
    border-collapse: collapse;
    text-align: center;
    padding: 1px 3px;
    border-width: 1px 1px 1px 0;
}

.iptable {
    border-left: 1px solid #ccc;
}

.yeardisc table td {
    border: 1px solid #ccc;
    border-collapse: collapse;
    padding: 1px 5px;
    border-width: 0 1px 1px 0;
}

.yeardisc table {
    text-align: left;
    border: none;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
}

.n_input {
    border: 1px solid #ccc;
    padding: 2px 5px;
}

.pad_t2 {
    padding-top: 2px;
}

.wid36 {
    width: 36% !important
}

.domainame {
    margin-left: 50px;
}

.domainame table {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}

.domainame table td {
    border: 1px solid #ccc;
    border-collapse: collapse;
    padding: 0px 5px;
    border-width: 0 1px 1px 0;
    line-height: 10px;
}

.width_7 {
    width: 7%;
}

.width28p {
    width: 28px !important;
}

.min50 {
    min-height: 50px;
}

.signateone {
    border: 1px solid #ccc;
    margin: 20px 0 0 60px;
    padding: 40px 6px 50px;
    text-align: center;
}

.b_b1s {
    border-width: 0 0 1px 0;
}

.signature.sign2 .signcol {
    height: 40px;
    line-height: 40px;
}

.signature.sign2 {
    padding-right: 0;
}

.pad_20t {
    padding-top: 20px;
}

.font15 {
    font-size: 15px;
}

.mplhed {
    position: relative;
    top: -5px;
}

.font12 {
    font-size: 12px;
}

.mar_r20 {
    margin-right: 20px;
}
.signate_mplscrf_in {
    border: 1px solid #ccc;
    margin: -35px -110px 0;
    padding: 70px 6px 20px;
    text-align: center;
    font-size: 9.5px;
}

.signate_mplscrf_ext {
    border: 1px solid #ccc;
    margin: -50px -26px 0px;
    padding: 70px 6px 20px;
    text-align: center;
    font-size: 9.5px;
}