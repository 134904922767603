.font20{ font-size:20px;} .text_l.domestictable table td {
    text-align: left;
} .intertable table{    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;}.intertable table th{ background:#aaa; color:#fff; text-align:left; padding:5px 10px;}
	.intertable table td{border: 1px solid #ccc;
    border-collapse: collapse;    border-width: 0 1px 1px 0;padding:5px 10px;}
.subtablein table{    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;}
	.subtablein table td{border: 1px solid #ccc;
    border-collapse: collapse;    border-width: 0 1px 1px 0;padding:10px 10px;}
	.subtablein table th{ font-weight:bold; text-align:left; padding:10px 10px;   border: 1px solid #ccc;
    border-width: 0px 1px 1px 0px;border-collapse: collapse; }
.font30{ font-size:30px;}	
.ligthbg_big {
    background: #d1d3d4;
    padding: 5px 10px;
    font-weight: bold;
    margin: 5px 0px 8px;
}.width48{ width:48%;}.nolist {
    padding: 0px 0 0 0px;
    list-style-type: none;
}.nolist li {
    margin: 0 0 10px;
}
.nolist li {
    list-style-type: none;
    display: flex;
}.nolist li span {
    display: flex;
}
.underline{ border-bottom:1px solid #FF0000;}
.pad_l20{ padding-left:20px;}
.thefallow{ color:#FF0000; padding:10px 0; border:1px solid #FF0000; border-width:1px 0;}
.subtablein.subin  table td{    padding:5px;}
.sealcom{ text-align:right; padding:5px 0; border-bottom:1px solid #FF0000;}
.limitedoffice{border-bottom:1px solid #FF0000; padding:0 0 10px; margin:0 0 10px;}
.width100{ width:98%;}
.stamsin{ text-align:right; padding:0 30px 0 0; }
.domestictable.tablepad td{ padding:8px 10px;}
.raised{ padding:10px; border:1px solid #ccc;}










