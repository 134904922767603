
.header_section{
    background: #f47920;
    color: #fff;
    height: 50px;
    width: 100%;
    position: fixed;
    z-index: 1;
    top:0px;
  }
  .header_sec_logo{
    padding-left: 20px;
    float: left;
  
  }
  .header_sec_logo > img{
    height: 40px;
    margin: 5px;
  }
  .header_sec_menu{
    padding-right: 20px; 
    float: right;
  }
  .header_sec_menu a{
    float: left;
    color: #fff;
    text-align: center;
    padding: 3px 10px;
    text-decoration: none;
    font-size: 14px; 
    line-height: 25px;
    border-radius: 4px;
    margin: 9px 10px;
    border: 1px solid #fff
  }
  .header_sec_menu a:hover{
    
    color: #f47920;
    background: #fff
  }