* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

$amber : #ee2737;
$white : #ffffff;
$grene : #707070;
$black : #000000;

body {
    height: 100vh;
    overflow: hidden;
}

.preview {
    width: 21cm !important;
    ;
    margin: 0 auto;
    background: $white;

    &.vil {

        // width:100%;
        .preview-header {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            height: 130px;
            background: $amber;

            .form-name {
                margin: auto;
                text-align: center;

                h3 {
                    color: $white;
                }
            }
        }

        .form-action {
            .action {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .btn-outline-primary {
                color: $black;
                border: 1px solid $amber;

                &:hover,
                &.active {
                    background-color: $amber;
                    color: $white;
                }
            }

        }
    }

    .preview-header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 130px;
        background: #e4e4e4;

        .form-name {
            margin: auto;
            text-align: center;

        }

        .btn {
            padding: 3px 8px;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            border: 1px solid $white;
            border-radius: 4px;
            background-color: Transparent;
            color: $white;
            min-width: 80px;
        }

        .btn:hover {
            color: $white;
        }

        .header-actions {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a {
                padding: 2px 5px;
                margin: 0 5px;
                border: 1px solid $white;
                border-radius: 5px;
                color: $white;
                box-shadow: 1px 1px 1px $grene;
            }
        }
    }

    .preview-container {
        padding: 0 30px;
        height: calc(100vh - 140px);
        overflow-y: scroll;
        overflow-x: hidden;
        background: $white;
        color: $black;

        :nth-child(1) {
            margin-top: 4px;
        }

        .action_area {
            padding: 0 20px !important;
            margin: 5px;

            &.display-block {
                display: block
            }

            button {
                padding: 2px 5px;
                margin: 0 5px;
                min-height: 30px;
                border: 1px solid $amber;
                border-radius: 5px;
                color: $white;
                box-shadow: 1px 1px 1px $grene;
                width: 200px;
                color: $black;
            }
        }

        .lastField {
            padding: 10px 2px 10px 10px;
            font-size: 16px;
            font-style: italic;
            color: $black;
            text-align: left;
            margin-top: 20px;
            margin-bottom: 10px;
            background: $grene;
        }

        .tdleft {
            color: $black;
            font-weight: 500;
        }

        .textbox {
            box-shadow: none;
        }

        .form-action {
            .btn {
                text-transform: uppercase;
                width: 250px;
                margin: 20px auto;
            }
        }

        .submit-msg {
            margin-top: 200px;
            height: 300px;
            text-align: center;
        }
    }
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }

    .subpage {
        padding: 1cm;
        border: initial;
        height: initial;
        min-height: initial;
        outline: 2cm $white solid;
    }
}