 .check15{ height:15px; width:15px;}
 .width78{ width:78%;}
 .center_70per{ width:70%; margin:0 auto;}
 .signate_nplccrf_in{
        border: 1px solid #ccc;
        margin: -85px -85px 0;
        padding: 70px 6px 20px;
        text-align: center;
        font-size: 9.5px;  
 }
 .signate_nplccrf_ext{
    border: 1px solid #ccc;
    margin: -85px -35px 0;
    padding: 70px 6px 20px;
    text-align: center;
    font-size: 9.5px;  
}