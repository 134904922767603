.check15 {
    height: 15px;
    width: 15px;
}

.width78 {
    width: 78%;
}

.center_70per {
    width: 70%;
    margin: 0 auto;
}

.pad_r10 {
    padding-right: 10px;
}

.changecol {
    padding: 5px;
    border: 1px solid #aaa;
    width: 90%;
    min-height: 175px;
}

.width80 {
    width: 80% !important;
}

.width70 {
    width: 68% !important;
}

.sectionlines {
    line-height: 17px;
}
.signate_box {
    border: 1px solid #ccc;
    margin: 20px 0 0;
    padding: 5px 6px 20px;
    text-align: center;
    font-size: 9.5px;
}