.brdr_b1 {
    border-width: 0 0 1px !important;
}

.top-10px {
    top: -10px !important;
}

.width92_sup {
    width: 92%;
}

.cross_fill {
    border: 1px solid #ddd;
    width: 22% !important;
    min-height: 130px;
    margin: 10px 0 0;
}

.customfom {
    color: #ee1c25;
}

.self_attach {
    text-align: center;
    padding: 40px 0 0;
    color: #81898b;
}

.ship_check {
    margin: 0 2px;
    position: relative;
    top: 2px;
}

.width57px {
    width: 57px !important;
}

.pad_r3 {
    padding-right: 3px;
}

.fnt7 {
    font-size: 7px !important
}

.fnt8 {
    font-size: 8px !important
}

.wid50per {
    width: 50% !important;
}

.ssidname.check input {
    top: 0;
}

.wifitable {
    padding: 5px 0;
}

.wifitable table {
    text-align: left;
    border: none;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
}

.wifitable table td {
    border: 1px solid #ccc;
    text-align: center;
    border-collapse: collapse;
    padding: 1px 5px;
    border-width: 0 1px 1px 0;
}

.width78 {
    width: 78%;
}

.list li {
    list-style-type: none;
}

.list {
    padding: 20px 0 0 0px;
}

.width33 {
    width: 33%;
}

.fon8_5 {
    font-size: 8.5px;
}

.othercheck {
    top: 3px !important;
    margin: 0;
    position: relative;
}

.posinput {
    position: relative;
    top: -13px !important;
}

.font7 {
    font-size: 7px;
}

.font_8 {
    font-size: 8px;
}

.pad_l_2 {
    padding-left: 10px;
}

.in_subput input {
    border: 1px solid #ccc;
    padding: 2px 5px;
    font-size: 10px;
    position: relative;
    border-width: 0 0 1px 0;
    top: -2px;
    width: 50px;
}

.wid83p {
    width: 83% !important
}

.wid80p {
    width: 78% !important
}

.pad_l_5 {
    padding-left: 5px;
}

.signsealwith {
    padding: 70px 0 0;
    font-size: 8px;
}

.width34 {
    width: 34%;
}

.activatesim {
    border: 1px solid #ddd;
}

.width40px {
    width: 40px !important;
}

.width100per {
    width: 85% !important;
}

.vilemp {
    text-align: center;
    padding: 50px 0 0;
}

.terms {
    color: #ca685f;
    text-transform: uppercase;
}

.width150px {
    width: 150px !important
}

.enroll_form {
    text-align: center;
    font-weight: bold;
    font-size: 25px;
    margin: 0 0 10px;
}

.sip_central {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 10px;
}

.typecheck.gstone .width12 {
    padding-right: 5px;
}

.pad_r50 {
    padding-right: 50px;
}

.width33 {
    width: 33%;
}

.decl {
    overflow: hidden;
    padding: 20px 0;
}

.dectext {
    width: 240px;
    padding: 15px;
    border: 1px solid #aaa;
    float: right;
}

.width93 {
    width: 93%;
}

.gen {
    text-align: center;
    font-size: 8px;
    font-weight: bold;
    text-decoration: underline;
    padding: 0 0 10px;
}

.pad_b10 {
    padding-bottom: 10px;
}

.termtable table {
    text-align: left;
    border: none;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
}

.termtable table td {
    border: 1px solid #ccc;
    text-align: left;
    border-collapse: collapse;
    padding: 5px 10px;
    border-width: 0 1px 1px 0;
}

.grybg {
    background: #ccc;
    padding: 10px 5px 0 5px;
    margin: 10px 0;
}