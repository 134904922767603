.field-timeline-history-container {
    width: 600px;
    height: 600px;
    min-height: 60vh;
    margin: auto;
    padding: 10px;
    border: 1px solid grey;
    background: #222222;
    border: 1px solid #bbb8b8;
    color: #bbb8b8 !important;
    border-radius: 10px;
}

.field-timeline-history-container+button[class^="styles_closeButton"] {
    left: 15px;
}

.timeline-header {
    padding-left: 15%;
}

.field-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 15px 0px;
    border-bottom: 1px solid grey;
    padding-bottom: 10px;
}

.timeline-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid grey;
    max-height: 540px;
    min-height: 540px;
    overflow-y: scroll;
}