.form_view_main {
    width: 70%;
    position: fixed;
    top: 130px;
    bottom: 2%;
    left: 15%;
    right: 0;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 3px;
}

.field_det_content {
    width: 100%;
    background: #f7f7f7;
    padding: 5px 5px 5px 20px;
    margin: 5px;
    min-height: 30px;
}

.field_edit_content {
    width: 100%;
    background: #f7f7f7;
    // padding: 5px;
    margin: 5px;
    min-height: 30px;
}

.field_det_content:hover span {
    display: block !important;
}

.field_det_content_img {
    width: 100%;
    background: #f7f7f7;
    padding: 5px;
    margin: 5px;
    text-align: center;
    min-height: 100px;
}

.field_det_content_img:hover span {
    display: block !important;
}

.tdleft_caf {
    width: 30% !important;
}

.tdright_caf {
    width: 50% !important;
}

.tdattach {
    width: 10%;
}

.tdicon {
    width: 4%;
}

.div_img_alert {
    height: 30px;
    width: 30px;
}

.div_img_alert>img {
    height: 23px;
    padding-top: 5px;
    position: relative;
    right: 35px;
}

.div_img_attach {
    height: 30px;
    cursor: pointer;
}

.div_img_attach>img {
    height: 30px;
    padding-top: 5px;
}

.div_action {
    color: #337ab7;
    cursor: pointer;
}

.div_span_edit {
    float: right;
    width: 20px;
    display: none;
}

.div_span_history {
    float: right;
    margin-right: 10px;
    width: 20px;
    display: none;
}

.div_span_img_edit {
    max-width: 100% !important;
    float: right;
    cursor: pointer;
}

.div_span_save {
    max-width: 10% !important;
    float: right;
    cursor: pointer;
}

.doc_sign_div {
    padding: 20px;
    text-align: center;
    display: inline-flex;
    margin-left: 30%;
    margin-top: 20px;
}

.doc_sign_div .right-mr {
    margin-right: 45px;
}

.no-spin::-webkit-inner-spin-button {
    -webkit-appearance: none;
}