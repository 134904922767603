.Formtable{
    width: 100%;
}
.Formtable .tdleft{
    padding-top: 5px;
    width: 40%;
    text-align: left;
    padding-bottom: 5px;
    vertical-align: top;
}
.Formtable .tdright{
    padding-top: 5px;
    width: 60%;
    text-align: left;
    padding-bottom: 5px;
    vertical-align: top;
    padding-left:5px;
}
.textbox{
    border: 1px solid #cbcbcb;
    border-radius: 3px;
    width: 100%;
    outline: none;
    padding: 4px;
    height: 30px;
}
.textarea_view > textarea{
    resize: vertical;
}
.textbox :focus{
    outline: none;
    background-color: #ccc;
}
.ButtonSubmit{
    padding: 10px;
    text-align: center;
    width: 100%;
}
.ButtonSubmit > button{
    
    text-align: center;
    width: 100px;
    background: #f47920;
    padding: 5px 10px;
    border-radius: 3px;
    box-shadow: 5px 6px 10px 0px #c5c5c5;
    color: #fff;
} 
.labelField{
    padding: 5px 2px 5px 10px;
    font-size: 10px;
    font-style: italic;
    color: #545454;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
    background: #cacaca;
}
.textarea{
    border: 1px solid #cbcbcb;
    border-radius: 3px;
    width: 100%;
    outline: none;
    padding: 4px;
    height: 50px;
}
.textarea :focus{
    outline: none;
    background-color: #ccc;
}
.checkboxfield{
    width: 20px;
    height: 20px;
    float: left;
}
.checkboxspan{
    padding-left: 10px;
    line-height: 25px;
}
.upload_dropzone_fields{
    width: 100%;
    height: 100px;
    border-width: 2px;
    border: 1px dashed #cbcbcb;
    padding: 5px;
    text-align: left;
    background: #fff;
}
.upload_dropzone_title{
    font-size: 14px;
    color: gray;
    padding: 5px;
    text-align: center;
}
.thumbnail_dropzone{
    text-align: center;
    margin: auto;
}
.thumbnail_dropzone img{
    height: 90px;
}
.margin-5 {
    margin: 5px;
}
.tdright{
    color: #252222;
}
.close-icon-bot-builder{
    margin-left: 434px;
}
/* .minus_icon {
    float: right;
    width: 20px;
    cursor: pointer;
} */
.dragfiles{
    display: flex;
    justify-content: space-between;
}