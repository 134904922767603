.npwidth17 {
    width: 17%;
}

.check.gstcheck .npwidth17 input.oth_gst {
    top: 1px;
    width: 70px;
}

.declartion {
    color: #ed1d24;
    font-weight: bold;
}

.connecticons span {
    display: inline-block;
    text-align: center;
    width: 20%;
    font-size: 18px;
    font-weight: 600;
    color: #ed1d24;
}

.url {
    width: auto;
    text-align: center;
    font-size: 22px;
    color: #ed1d24;
    font-weight: bold;
    margin: 20px 0 0;
}

.customfom {
    padding: 0 0 0 0px;
    color: #ed1d24;
    font-weight: normal;
    font-size: 20px;
    margin: 0 0 10px 0px;
    font-weight: 400;
}

.connectcol {
    padding: 100px 0 0;
}