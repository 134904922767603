@import url('https://fonts.googleapis.com/css?family=Herr+Von+Muellerhoff:400,500,700');
page {
	background: white;
	display: block;
	margin: 0 auto;
    margin-bottom: 0.5cm;/*box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);*/
    width: 21cm;
    min-height: 29.7cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
page[size="A4"] {
	width: 21cm; min-height:1000px; 
}
page[size="A4"][layout="landscape"] {
	width: 29.7cm;
	 
}
page[size="A3"] {
	width: 29.7cm;
	height: 42cm;
}
page[size="A3"][layout="landscape"] {
	width: 42cm;
	height: 29.7cm;
}
page[size="A5"] {
	width: 14.8cm;
	height: 21cm;
}
page[size="A5"][layout="landscape"] {
	width: 21cm;
	height: 14.8cm;
}
.section1 {
	padding: 80px;
}
.section1_top {
	text-align:right;
}
.section1_top_col {
	width: auto;
	display: block;
	text-align:left;
}
.pad_l_r10 {
	padding:0 10px;
}
.over_hid {
	overflow:hidden;
	clear:both;
	padding: 0 0 5px;
	line-height: unset;
	margin: -5px 0 0;
}
.pullr {
	float:right;
}
.customhed {
	padding: 0 0 0 30px;
	color: #ed1d24;
	font-weight: bold;
	font-size: 20px;
}
.empty1 {
	height:160px;
}
.vodabar {
	padding: 30px 60px;
	color: #fff;
	background-color: #ed1d24;
	font-size: 30px;
	font-weight: bold;
	letter-spacing: 1px;
	display: block;
	-webkit-print-color-adjust: exact;overflow: visible;
}
.connectcol {
	padding: 50px 0 0;
	text-align: center;
}
.ready {
	font-size: 27px;
	color: #ed1d24;
}
.connecticons img {
	margin-right:55px;
}
.url {
	width: auto;
	text-align: center;
	font-size: 22px;
	color: #ed1d24;
	font-weight: bold;
	margin: 20px 0 0;
}
.section2 {
	padding: 20px 40px;
}
.wid50 {
	width:50%;
}
.in_block {
	display:inline-block;
}
.customhed {
	padding: 0 0 0 0px;
	color: #ed1d24;
	font-weight: normal;
	font-size: 30px;
	margin: 0 0 8px 50px;
	font-weight: 600;
}
 @media print {
body, page {
	margin: 0;
	box-shadow: 0;
}
 
body {
	-webkit-print-color-adjust: exact;
}
}
.w_85 {
	width: 90%;
}
.font8 {
	font-size: 10px;
}
.caf {
	float:right;
	width: 200px;
	text-align: right;
}
.caf input {
	float:right;
	width: 110px;
	border: 1px solid #ccc;
	padding: 4px 5px;
	font-size: 10px;
	position: relative;
	top: -5px;
	margin: 0 0 0 15px;
}
.pd_t5 {
	padding-top:5px;
}
.pad_l10 {
	padding-left:10px;
}
.mar_t-5 {
	margin-top:-5px;
}
.form_col_input {
	border: 1px solid #ccc;
	padding: 2px 0px;
	font-size: 10px;
	border-width: 0 0 1px 0;
	width: 45%;
	position: relative;
	top: -2px;
}
.input_red{
	border: 3px solid rgb(241, 12, 12) !important;
	border-width: 3px;
}
.input_sign{
	font-family: 'Herr Von Muellerhoff', cursive;
	font-size: 25px;
	font-style: italic;
	transform: rotate(-10deg);
	font-weight: bold;
}
.under_line{
	text-decoration: underline;
}
.pad_red{
	color:rgb(241, 12, 12) !important;
	font-weight: bold;
}
.caf_inner {
	float:right;
	width: 200px;
	line-height: 16px;
}
.caf_inner input {
	float:right;
	width: 110px;
	border: 1px solid #ccc;
	padding: 4px 5px;
	font-size: 10px;
	position: relative;
}
.width45 {
	width:45%;
}
.fleft {
	float:left;
}
.auth input {
	border: 1px solid #ccc;
	padding: 2px 5px;
	font-size: 10px;
	position: relative;
	border-width: 0 0 1px 0;
	top: -2px;
}
.width25 {
	width:25%;
}
.width30 {
	width:30%;
}
.width40 {
	width:40%;
}
.width45 {
	width:45%;
}
.width72 {
	width:72%;
}
.width74 {
	width:74%;
}
.width60 {
	width:60%;
}
.width87 {
	width:87%;
}
.width54 {
	width:54%;
}
.width58 {
	width:58%;
}
.width42 {
	width:42%;
}
.font5 {
	font-size: 8px;
}
.ligthbg {
	background:#d1d3d4;
	padding: 2px 10px;
	font-weight:bold;
	margin: 5px 0px 8px;
}
.width50 {
	width:50%;
}
.width37px {
	width:37px;
	display:inline-block;
}
.width70 {
	width:70%;
}
.check input {
	border: 1px solid #ccc;
	padding: 2px 5px;
	font-size: 10px;
	position: relative;
	top: 0px;
	margin: 0;
	width: 11px;
}
.width15 {



	width:15%;
}
.width12 {
	width: 11%;
}
.typecheck {

	font-size: 10px;
}
.typecheck .width12 {
	padding-right: 0;
	width: auto;
	padding-right: 13px;
}
.pad_t7 {
	padding-top: 4px;
}
.width110px {
	width: 137px;
}
.width83 {
	width:83%;
}
.width65px {
	width: 65px;
}
.mplstech {
	width:20%;
}
.width16 {
	width:16%;
}
.mplstextin input {
	border: 1px solid #ccc;
	padding: 2px 5px;
	font-size: 10px;
	position: relative;
	top:3px;
}
.wid12 {
	width:12%;
}
.custype {
	font-size: 10px;
}
.fnt3_3 {
	font-size: 10px;
}
.fnt4 {
	font-size: 10px;
}
.mpls {
	font-size: 10px;
}
.pad_7t {
	padding-top: 2px;
}
.width70px {
	width: 100px;
}
.width80px {
	width:80px;
}
.width78 {
	width: 82%;
}
.gstcheck {
	font-size: 10px;
}
.check.gstcheck input.oth_gst {
	top: 2px;
	border-width: 0 0 1px;
	padding: 0;
	width: 80px;
}
.width14 {
	width:14%
}
.width15_2 {
	width: 16%;
}
.customfom {
	padding: 0 0 0 0px;
	color: #ed1d24;
	font-weight: normal;
	font-size: 20px;
	margin: 0 0 10px 0px;
	font-weight: 400;
}
.width85 {
	width:85%;
}
.width59 {
	width:59%;
}
.width80 {
	width:80%;
}
.width90 {
	width:90%;
}
.auth {
	clear:both;
	margin:3px 0 5px;
	padding: 0;
}
.pad_b5 {
	padding-bottom:5px;
}
.width54px {
	width: 54px;
	display: inline-block;
}
.width65 {
	width: 65%;
}
.width77 {
	width:77%;
}
.width66 {
	width: 66%;
}
.faxn {
	display: inline-block;
	width: 86px;
	text-align: right;
}
.fnt8{ font-size:8px;}
.fnt7{ font-size:7px;}
.fnt6{ font-size:6px;}
.sign{ margin:0;}
.gsttext{ margin:0 0 5px;}
.auth.remarks {     margin: -5px 0 5px;}
.auth.namsupp{ margin:0 0 5px;}
.margin0{ margin:0 !important;}
.mplstextin.padb10{ padding-bottom:10px;}
.padt5{ padding-top:5px;}
.signate {
	border: 1px solid #ccc;
	margin: 20px 0 0;
	padding: 20px 6px 20px;
	text-align: center;
	font-size: 9.5px;
}
.signate_img > img{
	height: 80px;

}
.float_img{
	position: absolute;
	border: none !important;
	padding: 0 !important;
	right: 23%;
	z-index: 1;
}
.float_img_illcaf{
	position: absolute;
	border: none !important;
	padding: 0 !important;
	right: 26%;
	z-index: 1;
}
.float_img_illcaf_ext{
	position: absolute;
	border: none !important;
	padding: 0 !important;
	right: 31%;
	z-index: 1;
}
.width30 {
	width:30%;
}
.pad_t20 {
	padding-top:20px;
}
.pad_t30 {
	padding-top:30px;
}
.width65 {
	width:65%;
}
.marb5 {
	margin-bottom:5px;
}
.flright {
	float:right;
}
.char .over_hid.pad_t5 {
	padding-top:3px;
}
.textarea {
	border: 1px solid #ccc;
	padding: 2px 2%;
	font-size: 10px;
	min-height:30px;
	width:95.8%;
}
.mar_l7 {
	margin-left:7px;
}
.width_12 {
	width:12%;
}
.pad_t5 {
	padding-top:5px;
}
.width7 {
	width:7%;
}
.top-3 {
	top:-3px !important;
}
.pad_t15 {
	padding-top:15px;
}
.cos .width10 {
	font-size:7px;
	width: 11%;
}
.font9 {
	font-size:9px;
}
.fon8 {
	font-size:8px;
}
.textcenter {
	text-align:center;
}
.lm {
	border-width: 0 0 1px 0 !important;
	width: 42% !important;
	top: -3px !important;
	margin-left: 3px !important;
}
.lm.nill {
	width:57% !important;
}
.width6 {
	width:8%;
}
.width52 {
	width:52%;
}
.width13 {
	width:13%;
}
.width24 {
	width:24%;
}
.pd_t3 {
	padding-top:3px;
}
.font9 {
	font-size:9px;
}
.width75 {
	width: 75% !important;
}
.width90 {
	width: 90% !important;
}
.width10 {
	width:10%;
}
.width_6 {
	width:6%;
}
.width130px {
	width:130px;
}
.width170px {
	width:175px;
}
.width110px {
	width:110px;
}
.padl5 {
	padding-left:5px;
}
.pad_t3 {
	padding-top:3px;
}
.pad_t6 {
	padding-top:6px;
}
.pad_t10 {
	padding-top:10px;
}
.inputext {
	border: 1px solid #ccc;
	padding: 2px 5px;
	font-size: 10px;
	position: relative;
	border-width: 0 0 1px 0 !important;
	top: -2px;
}
.pad_5t {
	padding-top:5px;
}
.connecticons span {
	display:inline-block;
	text-align:center;
	width:20%;
	font-size: 18px;
	font-weight:600;
	color: #ed1d24;
}
.connecticons span img {
	display:inline-block;
	margin:0;
}
.domestictable {
	text-align:right;
	padding: 0 0 10px;
	clear: both;
	overflow: hidden;
}
.domestictable table {
	text-align:left;
	float:right;
	border: none;
	border: 1px solid #ccc;
	border-width: 1px 0 0 1px;
}
.domestictable table td {
	border: 1px solid #ccc;
	border-collapse: collapse;
	padding: 1px;
	border-width: 0 1px 1px 0;
}
.pad_b5 {
	padding-bottom:5px;
}
.regtext {
	text-transform:uppercase;
	font-weight:bold;
	padding:5px 0 3px;
	border-bottom:1px solid #ccc;
}
.fright {
	float:right;
}
.width49 {
	width:49%;
}
.width84 {
	width:84%;
}
.font6 {
	font-size:6px;
}
.font10 {
	font-size:10px;
}
.widthfull {
	width:100% !important;
}
.inputext_brd {
	border: 1px solid #ccc;
	padding: 1px 5px;
	font-size: 10px;
	position: relative;
	top: 0;
	margin: 0 0 5px;
}
.inputext_brd_list {
	border: 1px solid #ccc;
	padding: 3px 5px;
	font-size: 10px;
	position: relative;
	top: 0;
	margin: 0 0 5px;
}
.design {
	float: right;
	width: 58% !important;
	margin-right: 10px;
}
.list {
	padding: 20px 0 0 15px;
	list-style-type: none;
}
.list li {
	margin: 0 0 10px;
}
.connecticons span{ display:inline-block; text-align:center; width:20%;font-size: 18px; font-weight:600;
    color: #ed1d24;}
 .connecticons span img{ display:inline-block; margin:0;}
	.domestictable{ text-align:right;}
	.domestictable table{text-align:left;float:right;border: none;border: 1px solid #ccc;border-width: 1px 0 0 1px;}
	.domestictable table td{border: 1px solid #ccc;border-collapse: collapse;padding: 1px;border-width: 0 1px 1px 0;}
	.pad_b5{ padding-bottom:5px;}
	.regtext{ text-transform:uppercase; font-weight:bold; padding:5px 0 3px; border-bottom:1px solid #ccc;}
    .fright{ float:right;}
	.width49{ width:49%;}
	.width84{ width:84%;}
	.font6{ font-size:6px;}
	.font10{ font-size:10px;}