 .cross_fill {
    border: 1px solid #ddd;
    width: 22% !important;
    min-height: 130px;
    margin: 10px 0 0;
}.self_attach {
    text-align: center;
    padding: 20px 0 0;
    color: #ccc;
}
.width48{ width:48%;}
.width35{ width:35%;}	
.font7{ font-size:8.3px;}	
.wid12per{ width:11%;} 
.sealone{ position:relative; border:1px solid #ccc; min-height:100px; margin:5px 0 0 10px;}	
.sealtext1{ position:absolute; left: 10px;
    right: 10px;
    bottom: 3px;}	
	.sealtext2{     position: absolute;
    top: 30px;
    left: 10px;
    right: 10px;
    text-align: center;
    font-size: 17px;
    color: #ccc;}.sealtext3{      position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    font-size: 13px;
    color: #ccc;
    line-height: 13px;}.sealtext4{position: absolute;
    left: 5px;
    right: 5px;
    bottom: 0;}
	.sealtwo{ position:relative; border:1px solid #ccc; min-height:80px; margin:5px 0 0 10px;}	
.sealfour	{ position:relative; border:1px solid #ccc; min-height:60px; margin:5px 0 0 10px;}	
.vodahedcol{ position:absolute; top:3px; right:5px; width:55%;}	
.vodahedcol p{ margin:0;}	
.vodahedcol p input	{ height:8px;}
.vodatex{position: absolute;
    left: 10px;
    top: 15px;
    font-size: 14px;
    color: #ccc;}	
	.vodasign{ position:absolute; bottom:5px; left:5px;}
	.pad_t40{ padding-top:40px;}
.asent{    position: absolute;
    left: 0;
    font-size: 7px;
    top: 13px;
    line-height: 8px;
    width: 66px;}	
.relati{ position:relative;}	
.sealone_2 {
    position: relative;
    border: 1px solid #ccc;
    min-height: 80px;
    margin: 5px 0 0 10px;
}
.sealtext21 {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    font-size: 14px;
    color: #ccc;
    line-height: 13px;
}
.width33{ width:33%;}
.width47{ width:47%;}
.cuttingcard{ height:2px; border-bottom:1px dashed #FF0000; margin:30px -40px;}
.custext{ margin:20px 0 0}
.shipcheck{margin: 0 0 7px;
    position: relative;
    top: 4px;}
.ipslinc{ margin:0 0 0 0px !important; position:relative; top:0px !important;}
.pad_l15{ padding-left:15px;}
.custypeone{ font-size:9px;}
.wifitable table {
    text-align: left;
    border: none;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
}.wifitable table td {
    border: 1px solid #ccc;
    text-align: center;
    border-collapse: collapse;
    padding: 1px 5px;
    border-width: 0 1px 1px 0;
}
.wid100px{ width:100px !important;}
	.wid50per {
    width: 50% !important;
}
.inchar{border: 1px solid #eee;
    padding: 5px;
    margin-top: -8px;}
	.reglat{ padding:0 0 5px; margin:0 0 10px; border-bottom:1px solid #ddd;} 