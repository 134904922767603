$amber : #ee2737;
$white : #ffffff;
$grene : #707070;
$black : #000000;
body{
    height: 100vh;
    overflow: hidden;
}
.layout {
    min-width: 600px;
    width: 65vw;
    margin: auto;

    &-vil {
        background: $amber;
    }

    &-header {
        height: 150px;
        width: 100%;

        .logo {
            width: 80px;
            margin: auto 25px;
        }

        .title {
            margin: auto;
            text-align: center;

            h3 {
                color: $white;
                text-transform: capitalize;

            }
        }

        .btn {
            padding: 3px 8px;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            border: 1px solid $white;
            border-radius: 4px;
            background-color: Transparent;
            color: $white;
            min-width: 80px;

            &:hover {
                color: $white;
            }

        }

        .action {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a {
                padding: 2px 5px;
                margin: 0 5px;
                border: 1px solid $white;
                border-radius: 5px;
                color: $white;
                box-shadow: 1px 1px 1px $grene;

            }
        }

    }

    &-container {
        width: 100%;
        height: calc(100vh - 150px);
        overflow-y: scroll;
        background: $white;
        color: $black;
    }
}