.brdr_b1 {
    border-width: 0 0 1px !important;
}

.top-10px {
    top: -10px !important;
}

.width92_sup {
    width: 92%;
}

.cross_fill {
    border: 1px solid #ddd;
    width: 22% !important;
    min-height: 130px;
    margin: 10px 0 0;
}

.customfom {
    color: #ee1c25;
}

.self_attach {
    text-align: center;
    padding: 40px 0 0;
    color: #81898b;
}

.ship_check {
    margin: 0 2px;
    position: relative;
    top: 2px;
}

.width57px {
    width: 57px !important;
}

.pad_r3 {
    padding-right: 3px;
}

.fnt7 {
    font-size: 7px !important
}

.fnt8 {
    font-size: 8px !important
}

.wid50per {
    width: 50% !important;
}

.ssidname.check input {
    top: 0;
}

.wifitable {
    padding: 5px 0;
}

.wifitable table {
    text-align: left;
    border: none;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
}

.wifitable table td {
    border: 1px solid #ccc;
    text-align: center;
    border-collapse: collapse;
    padding: 1px 5px;
    border-width: 0 1px 1px 0;
}

.width78 {
    width: 78%;
}

.list li {
    list-style-type: none;
}

.list {
    padding: 20px 0 0 0px;
}